/* font */

@font-face {
  font-family: 'Helvetica_Now_Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Helvetica-Now-Text.woff') format('woff');
}
@font-face {
  font-family: 'Helvetica_Now_Medium';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Helvetica-Now-Text-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Helvetica_Now_Bold';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Helvetica-Now-Text-Bold.woff') format('woff');
}


body {
  margin: 0;
}

*{
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-weight: normal !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* beighe.leggero : #edefea; */
/* #edede9 */

b{
  color:#0e473f;
}

header{
  margin:0 !important;
}

.download-btn{
  background: #0e473f;
  padding: 8px 20px;
  border:none;
  margin-bottom: 15px;
  color:white;
  border-radius: 30px;
  border: 2px solid #0e473f;
  cursor: pointer;
}
.download-btn:hover{
  background: white;
  color: #0e473f;
}

.MuiPaper-elevation8{
  min-width: 400px !important;
}

.logoImg{
  max-width: 40px;
  margin-right: 15px;
}
.mainColor{
  color: 'white';
}
.MuiContainer-maxWidthXl{
  margin-top: 25px;
  margin-bottom: 50px;
  padding-left: 25px;
}

.mapTable .MuiTableCell-root{
  padding:6px !important;
}

.tooltipContainer{
  position: absolute;
  top:180px;
  left:15px;
  width:300px;
}
.tooltipTotale{
  background: white;
  border:2px solid #0e473f;
  padding:10px;
  font-size: 16px;
  font-family: 'Helvetica_Now_Regular';
}
.tooltipTotale ul{
  list-style-type: none;
  font-size: 16px;
  padding:0px;
  margin:0px;
}
.tooltipTotale span{
  color: #0e473f;
  font-size: 16px;
  font-family: 'Helvetica_Now_Medium';

}

.true button{
  background: #0e473f !important;
}
.true span svg{
  color:white !important;
}

.insertContainer{
    padding-top: 30px;
    margin-top:0;
    margin-bottom:30px;
    flex-grow: 1;
}
.insertContainer .MuiOutlinedInput-input{
  padding: 15px 14px;
}
.insertContainer .MuiOutlinedInput-multiline{
    padding: 15px 14px;
    max-height: 150px;
}
.insertContainer .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 6px;
}
.insertContainer .MuiFormLabel-root{
  font-size: .95rem;
}

@media (min-width: 600px){
  .MuiToolbar-regular {
      min-height: 55px !important;
      height: 55px;
  }
}

.MuiButton-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    letter-spacing: 1px !important;
}
.makeStyles-title-4 {
    flex-grow: 1;
    font-weight: 600 !important;
}
.makeStyles-inputInput-135 {
    border-radius: 0px !important;
}
.MuiPaper-rounded {
    border-radius: 0px !important;
}
.MuiTypography-gutterBottom {
    margin-bottom: 0em !important;
}
.makeStyles-title-181 {
    opacity: 1;
    font-size: 16px !important;
    margin-left: 10px;
}
.makeStyles-spec-177 {
    font-size: 18px;
    max-width: 300px;
    font-weight: bold;
    margin-bottom: 15px !important;
}
.MuiTab-wrapper {
    letter-spacing: 1px !important;;
    font-size: 14px !important;
}
.MuiTabs-flexContainer {
    display: flex;
    justify-content: center !important;
}

.containerCard{
  background-color: white;
  border-radius: 0px;
  padding:20px;
  margin-top: 20px;
  border:2px solid #0e473f;
}

.input .MuiInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: none !important;
    pointer-events: none;

}


.input .MuiInputBase-root {
  -webkit-box-shadow: 10px 10px 40px -23px rgba(0,0,0,1)!important;
  -moz-box-shadow: 10px 10px 40px -23px rgba(0,0,0,1)!important;
  box-shadow: 10px 10px 40px -23px rgba(0,0,0,1)!important;
  }

#deckgl-overlay{
  top:57px !important;
}
#deckgl-overlay{
  height: calc( 100% - 57px ) !important;
}
#view-child-default-view-0{
  top:57px !important;
}

.MuiCardContent-root:last-child {
    padding-bottom: 10px !important;
}
.MuiCardContent-root {
    padding: 10px !important;
}
.MuiTypography-h5 {
    font-size: 1.2rem !important;
    font-family: 'Helvetica_Now_Medium', "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.33;
    letter-spacing: 0em;
}


.MuiPickersCalendarHeader-switchHeader {
    display: flex;
    margin-top: 1px !important;
    align-items: center;
    margin-bottom: 2px !important;
    justify-content: space-between;
}
.MuiPickersCalendar-transitionContainer {
    margin-top: 5px !important;
    min-height: 216px;
}
.MuiPickersDay-day .MuiIconButton-label{
  align-self: end !important;
}

/* .MuiTypography-body1 {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    max-width: 300px;
} */
.MuiTypography-body2 {
    font-size: 16px !important;
    font-family: 'Helvetica_Now_Regular' !important;
      font-weight: normal;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    margin-bottom: 5px !important;
}
.makeStyles-btn-5 {
    padding: 18px !important;
}

.storic-page .MuiTableCell-root {
    padding: 0px 5px !important;

}
.storic-page .MuiIcon-root {
  height: 0.9em !important;
  font-size: 1.3rem !important;

}

.storic-page .MuiIconButton-root{
  padding: 8px !important;
}
.storic-page td{
  /* text-align: center; */
}

.table-storic{
  background: red !important;
}
.simili td, .simili th{
    font-family: 'Helvetica_Now_Regular' !important;
  font-size: 16px !important;
  padding:5px 10px;
  /* text-align: center; */
}


.MuiListItemText-multiline {
    margin-top: 0px!important;
    margin-bottom: 0px!important;
}
.MuiListItemText-root {
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 0px!important;
    margin-bottom: 0px!important;
}
.MuiListItem-root {
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    /* text-align: left; */
    align-items: center;
    padding-top: 5px!important;
    padding-bottom: 8px;
    justify-content: flex-start;
    text-decoration: none;
}

.MuiPickersDay-day {
    width: 36px;
    height: 36px;
    margin: 0 2px;
    padding: 7px !important;
    /* text-align: center; */
    font-size: 1.5rem;
}

#sa{
  height: 15px;
  width:15px;
  background-color:white;
  border:3px solid #ec5208;
  border-radius: 50%;
}
#sr{
  height: 15px;
  width:15px;
  background-color:white;
  border:3px solid #0a0a0a;
  border-radius: 50%;
}
#es{
  height: 15px;
  width:15px;
  background-color:white;
  border:3px solid #ec5208;
}

#er{
  height: 20px;
  width:20px;
  background-color:#ec5208;
}

#ox{
  height: 20px;
  width:20px;
  background-color:#0a0a0a;
  border-radius: 50%;
}

#hb{
  height: 20px;
  width:20px;
  background-color:#0a0a0a;
}

#ex img{
width:20px;
}

/* WWWWWWW */

#wsa{
  height: 15px;
  width:15px;
  background-color:white;
  border:3px solid #249757;
  border-radius: 50%;
}
#wsr{
  height: 15px;
  width:15px;
  background-color:white;
  border:3px solid #ae47c8;
  border-radius: 50%;
}
#wes{
  height: 15px;
  width:15px;
  background-color:white;
  border:3px solid #249757;
}

#wer{
  height: 20px;
  width:20px;
  background-color:#249757;
}

#wox{
  height: 20px;
  width:20px;
  background-color:#ae47c8;
  border-radius: 50%;
}

#whb{
  height: 20px;
  width:20px;
  background-color:#ae47c8;
}
#wex img{
width:20px;
}

/* tooltip */

.tooltip-left{
  position: absolute;
}




/* inserimento dati  */
.titolo-form{
  width:100%;
  font-size: 20px;
  margin-top:10px;
  margin-left:0px;
  margin-bottom: 20px;
  color:#0e473f;
  font-family: 'Helvetica_Now_Medium' !important;
  font-weight: normal !important;
}

 .MuiFormLabel-root {
    color: rgba(0, 0, 0, .75) !important;
    padding: 0;
    font-size: 1rem;
    font-family: 'Helvetica_Now_Medium' !important;
    font-weight: normal;
    line-height: 1;
    letter-spacing: 0.00938em;
}

.container{
  height:100vh !important;
}

/* td span[aria-hidden="true"]{
  color:#1d5e55;
} */

.MuiCheckbox-colorSecondary.Mui-checked {
    color: rgba(0, 0, 0, 0.54) !important;
}
.MTableToolbar-root-124 {
    padding-left: 15px;
}

/* spinner */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-left: 140px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: grey;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}





.MuiBadge-anchorOriginTopRightRectangle {
    top: 10px!important;
    right: 30px!important;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
    position: relative!important;
    background-color: #0e473f !important;
    color: white!important;
}


.mapTable{
  max-height: 60vh;
}

.MuiPaper-elevation1 {
    box-shadow:none !important;
}
.MuiList-padding {
    padding:0px !important;
}
.MuiExpansionPanelSummary-root {
    padding: 0px !important;
}
.MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 25px!important;
}
.MuiIconButton-edgeEnd {
    margin-right: 7px !important;
}
.MuiCollapse-entered {
    height: auto;
}

.MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0px !important;
    height: 15px !important;

}
.sinonimiContainer{
  padding-bottom: 10px;
  padding-top: 0px;
  overflow: scroll;
  max-height: 300px;
}

.MuiExpansionPanelSummary-content {
    margin: 0px !important;
    height:15px !important;
}




.MuiDrawer-modal .MuiToolbar-root{
  padding-left: 0px !important;

}
.MuiDrawer-modal h6{
  padding-left: 15px !important;
  padding-top: 10px !important;
  color:#0e473f !important;
  font-size: 1.15rem;
}






/* mappa */
.totalBtn{
  position: absolute;
  right: 22px;
  top: 366px;
}
.totalBtn button{
background: #fff;
border: 2px solid #0e473f !important;
}
.totalBtn svg{
color: #0e473f !important;
}
.totalBtn:hover svg{
  color:white !important;
}

.downloadBtn{
  position: absolute;
 right: 18px;
 top: 100px;
}


.navigationControl{
  position: absolute;
  right: 28px;
  top:420px;
   z-index:1;
}
.mapboxgl-ctrl-group {
    border-radius: 0px !important;
    background: #fff;
    border: 2px solid #0e473f !important;
}
.layerBtn{
  position: absolute;
  right: 22px;
  top: 290px;
}

.datamap{
  position: absolute;
   right: 18px;
  top: 30px;
}
.inputSpecie{
  position: absolute;
   left: 15px;
  top: 100px;
   width:375px;
}
.infospecie{
  position: absolute;
  left: 15px;
  bottom: 10px;
 width:375px;
 z-index: 9;
}
.legenda{
  position: absolute;
  right: 15px;
  bottom: 10px;
  width:350px;
  z-index: 2;
}

.MuiCollapse-wrapper header{
  color: #0e473f;
    background-color: transparent;
    box-shadow: none !important;
}
.MuiCollapse-wrapper header .MuiTabs-indicator{
  background-color: #0e473f;
}

.card-close{
  position:absolute !important;
  top:2px;
  right:20px;
  padding:10px;

  }

.credits{
  font-family: 'Helvetica_Now_Regular' !important;  position: absolute;
  bottom:10px;
  left:10px;
  font-size: 10px;
}
.credits a{
  color:black;
  text-decoration: underline;
}
.PrivateSwipeArea-anchorBottom-275 {
    top: auto;
    right: 0;
    display: none;
    bottom: 0;
}






.mapboxgl-ctrl-logo{display: none !important}
/* responsive mobile  */

.validation{
  color:#dd3300;
  font-family: 'Helvetica_Now_Regular' !important;
  font-size: 12px;
}
.MuiBadge-anchorOriginTopRightRectangle {
    transform: scale(1) translate(100%, -50%) !important;
    transform-origin: 100% 0%;
}

@media (max-width: 768px){



  .layerBtn{
    right: 22px;
    top: 215px;
  }
   .MuiFab-sizeMedium {
    width: 38px !important;
    height: 38px !important;
}
  .totalBtn {
      position: absolute;
      right: 22px;
      top: 282px;
  }
    .info-card{
      width: calc(100vw - 30px) !important;
      max-height: 600px;
    }
    .infospecie{
      z-index: 999 !important;
      width: auto !important;
    }
    .infospecie p{
      margin-bottom: 3px !important;
    }
    .MuiCardContent-root .card-close{
      position:absolute !important;
      top: 5px !important;
      right: 15px !important;
      padding:10px;
      border:none !important;
      }

    .legenda{
      right: 15px;
      width: calc(100vw - 30px) !important;
      z-index: 99 !important;
    }

    .legenda-container li{
      padding-bottom: 0px;
      padding:0px;
    }
    .legenda-container{
      max-height: 500px !important;
      overflow-y: scroll !important;
}
    .legenda-container .MuiCardContent-root{
      padding:2px !important;
      padding-bottom:50px;
    }
    .MuiTab-wrapper{
      font-size: 12px !important;
    }

  .tooltipContainer{
    position: absolute;
    bottom: 70px;
    top:unset;
    z-index: 999;
    left: 15px;
    width: calc(100vw - 30px) !important;

    }

    .logo{
    flex-grow: 1 !important;
    font-size: 17px !important;
    text-decoration: none;
    font-family: 'Helvetica_Now_Medium'!important;

    }
    .logo a{
      text-decoration: none;
    }
    .MuiToolbar-regular {
        min-height: 50px !important;
        display: flex !important;
        justify-content: space-between;
    }
    #view-child-default-view-0 {
        top: 52px !important;
    }
    #deckgl-overlay {
    top: 52px !important;
    height: calc( 100% - 52px ) !important;

  }
    .makeStyles-btn-5 {
        padding: 5px !important;
    }

    .inputSpecie{
      position: absolute;
     left: 15px;
      top: 60px;
       width: calc(100vw - 30px) !important;
       z-index: 99 !important;
    }
    .inputSpecie input{
      padding:10px !important;
    }
    .navigationControl{
     right: 10px;
     z-index: 0;
     display: none !important;
    }
    .downloadBtn{
      display:none;
    }
    .sinonimi{
      display: none;
    }
}



/* override stile  */

.storic-page .MuiPaper-root{
  padding:10px;
}
.storic-page .MuiPaper-root .MuiToolbar-root{
  padding-left: 5px !important;

}
.storic-page .MuiPaper-root .MuiToolbar-root h6{
  font-family: 'Helvetica_Now_Medium' !important;
  font-weight: normal !important;
}

th{
  font-family: 'Helvetica_Now_Medium' !important;
  font-weight: normal !important;
}
.logo-desktop{
  width:100%;
  font-size: 1.15rem !important;
  color: white !important;
  font-family: 'Helvetica_Now_Medium' !important;
  font-weight: normal !important;
}
.navbar-desktop{
  box-shadow: none!important;
  background-color: #0e473f !important;
  border-bottom: 2px solid #0e473f !important;
}
.navbar-desktop span{
  letter-spacing: 0.01071em !important;
  font-family: 'Helvetica_Now_Medium' !important;

}
.navbar-desktop a{
  text-transform: none;
  font-size: 1rem;
  color: white !important;
}
.inputSpecie{
  border:2px solid #0e473f;
}
.MuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 0px solid #0e473f;
    pointer-events: none;
}
.info-card{
  border:2px solid #0e473f;
}

.datamap button, .downloadBtn button, .layerBtn button{
  background: white;
  border:2px solid #0e473f;
  color:#0e473f;
}
.datamap button:hover, .downloadBtn button:hover, .layerBtn button:hover{
  background: #0e473f;
  border:2px solid #0e473f;
  color:white;
  transition-duration: 0s !important;
}

.legenda-container{
  border:2px solid #0e473f;
}
.legenda svg{
  color:#0e473f;
}
.makeStyles-cardInner-198 button:hover{
  background: #0e473f !important;
}
.makeStyles-cardInner-198 button:hover svg{
  color: white !important;
}

.MuiCollapse-wrapper header {
    border-bottom: none;
}

.card-close{
  border-radius: 0% !important;
  right:0px !important;
  top:0px !important;
  color:#0e473f !important;
  border:2px solid #0e473f !important;

}
.card-close:hover{
background: #0e473f !important;
color:white !important;
transition-duration: 0s !important;
}

.MuiIconButton-edgeEnd {
    margin-right: -9px !important;
    color:#0e473f !important;
}
.MuiInput-underline:after {
    border-bottom: 0px solid #0e473f !important;
}

.MuiDrawer-paper{
  border-top: 2px solid #0e473f !important;
}

.navbar-desktop svg{
  color: #ffffff;
  padding: 5px;
  height: 18px;
  width: 18px;
  background: transparent;
  border-radius: 40px;
  border: 2px solid white;
}

.quadrante{
  background:white;
  border-radius: 0px;
  position: absolute;
  top:100px;
  right: 70px;
}
.quadrante .MuiPaper-root{
  border:2px solid #0e473f;
}
.quadrante h2{
  color:#0e473f;
}
.MuiButton-root{
  border-radius: 0px !important;
}
.tabs .MuiTab-root:hover{
  background: white !important;
}
.MuiInputBase-root{
  border-radius: 0px !important;
}
.legenda .MuiBox-root{
  padding:10px !important;
}
.mapTable .MuiPaper-root{
  box-shadow: none !important;
  border: 2px solid #0e473f;
  padding: 5px;
}

.MuiTableCell-root, .MuiInputLabel-outlined{
  font-family: 'Helvetica_Now_Regular' !important;
  font-weight: normal !important;
}

.titolo-card{
  font-family: 'Helvetica_Now_Medium' !important;
  font-size:18px !important;
  margin-bottom:15px !important;
  max-width:100% !important;
}
.info-card p, .info-card span{
  font-family: 'Helvetica_Now_Regular' !important;
}

.mapTable th, .mapTable h6{
  font-family: 'Helvetica_Now_Medium' !important;
}
.storic-page TH{
  font-family: 'Helvetica_Now_Medium' !important;
}
@media (max-width: 768px){
.navbar-desktop a{
  font-weight: 500;
  }
}
